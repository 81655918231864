<template>
  <div>
    <!-- 添加资源 -->
    <div class="box">
      <div class="title_box flex">
        <Title :title="$route.query.id ? '编辑资源' : '添加资源'" />
      </div>
      <div class="ct_box">
        <ul class="flex nav_list">
          <li @click="routeFn(index)" :class="$store.state.active == item.id ? 'active' : ''"
            v-for="(item, index) in nav_list" :key="index">
            {{ item.title }}</li>
        </ul>
        <div class="form_box">
          <router-view></router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Title from '@/components/UserInfoTitle.vue'
export default {
  components: {
    Title,
  },
  computed: {
  },
  mounted() {
    this.getNav()

  },
  data() {
    return {
      nav_list: []
    }
  },
  methods: {
    getNav() {
      let url_arr = []
      this.curlGet('/api/medium_category/list').then(res => {
        if (res.data.code) {
          this.nav_list = res.data.data
          url_arr = ['/user/add_resource/News', '/user/add_resource/WeMedia', '/user/add_resource/PaperMedia', '/user/add_resource/OfficialAccounts', '/user/add_resource/Wb', '/user/add_resource/RedBook', '/user/add_resource/ShortVideo']
          this.nav_list.forEach((item, index) => {
            this.nav_list[index].path = url_arr[index]
          })

        }
      })
    },
    routeFn(index) {
      this.$router.push({
        path: this.nav_list[index].path,
      })

    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/AddResource';
</style>